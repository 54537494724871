import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { WEBSITE_SETTINGS_PATH } from '../constants';
import { Logger } from '../utils/logger';
import { FetchClient } from './FetchClient';

interface AmplifyClientSettings extends Record<string, string> {
    stage: string;
}

export enum ChangePasswordError {
    Unknown,
    InvalidParameter,
    Authentication,
    PasswordPolicy,
    CodeMismatch,
    NotInitiated,
}

export enum ResetPasswordError {
    Unknown,
    InvalidParameter,
    InvalidUsername,
    NotAuthorizedException,
}

export enum SignInError {
    Unknown,
    NotAuthorizedException,
    PasswordResetRequiredException,
}

export const AMPLIFY_INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException';
export const AMPLIFY_NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException';
export const AMPLIFY_INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException';
export const AMPLIFY_CODE_MISMATCH_EXCEPTION = 'CodeMismatchException';
export const AMPLIFY_USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';
export const AMPLIFY_PASSWORD_REQUIRED_EXCEPTION = 'PasswordResetRequiredException';

export const ChangePasswordErrorMap = new Map([
    [AMPLIFY_INVALID_PARAMETER_EXCEPTION, ChangePasswordError.InvalidParameter],
    [AMPLIFY_NOT_AUTHORIZED_EXCEPTION, ChangePasswordError.Authentication],
    [AMPLIFY_INVALID_PASSWORD_EXCEPTION, ChangePasswordError.PasswordPolicy],
    [AMPLIFY_CODE_MISMATCH_EXCEPTION, ChangePasswordError.CodeMismatch],
]);

export const ResetPasswordErrorMap = new Map([
    [AMPLIFY_INVALID_PARAMETER_EXCEPTION, ResetPasswordError.InvalidParameter],
    [AMPLIFY_USER_NOT_FOUND_EXCEPTION, ResetPasswordError.InvalidUsername],
    [AMPLIFY_NOT_AUTHORIZED_EXCEPTION, ResetPasswordError.NotAuthorizedException],
]);

export const SignInErrorMap = new Map([
    [AMPLIFY_NOT_AUTHORIZED_EXCEPTION, SignInError.NotAuthorizedException],
    [AMPLIFY_PASSWORD_REQUIRED_EXCEPTION, SignInError.PasswordResetRequiredException],
]);

export class AmplifyClient {
    private static federatedSignInOptions = (stage: string) => ({ customProvider: `HRPCognitoFederate-${stage}` });

    private static completePasswordPendingUser: any;

    static userSession(): Promise<CognitoUserSession> {
        return Auth.currentSession();
    }

    /**
     * On success this will trigger a "signIn" event on the Amplify Hub "auth" channel.
     *
     * @param username
     * @param password
     */
    static signIn(username: string, password: string) {
        Logger.debug(`sending sign in request for user:<${username}>`);
        return Auth.signIn(username, password).catch((error) => {
            Logger.error(error);
            const signInError = SignInErrorMap.get(error.code);
            throw signInError ?? SignInError.Unknown;
        });
    }

    /**
     * On success this will trigger a "signIn" event on the Amplify Hub "auth" channel.
     */
    static async federatedSignIn() {
        Logger.debug(`attempting federated sign in`);
        const settings = await FetchClient.fetch<AmplifyClientSettings>(WEBSITE_SETTINGS_PATH);
        return Auth.federatedSignIn(AmplifyClient.federatedSignInOptions(settings.stage));
    }

    /**
     * On success this will trigger a "signOut" event on the Amplify Hub "auth" channel.
     */
    static signOut() {
        Logger.debug(`signing out`);
        return Auth.signOut();
    }

    static initiateCompleteNewPassword(user: any) {
        AmplifyClient.completePasswordPendingUser = user;
    }

    /**
     * On success this will trigger a "signIn" event on the Amplify Hub "auth" channel.
     */
    static completeNewPassword(password: string) {
        if (!AmplifyClient.completePasswordPendingUser) {
            return Promise.reject(ChangePasswordError.NotInitiated);
        }

        return Auth.completeNewPassword(AmplifyClient.completePasswordPendingUser, password)
            .then(() => {
                AmplifyClient.completePasswordPendingUser = undefined;
            })
            .catch((error) => {
                Logger.error(error);
                const passwordError = ChangePasswordErrorMap.get(error.code);
                throw passwordError ?? ChangePasswordError.Unknown;
            });
    }

    static async changePassword(oldPassword: string, newPassword: string) {
        const currentUser = await Auth.currentAuthenticatedUser();

        return Auth.changePassword(currentUser, oldPassword, newPassword, {}).catch((error) => {
            Logger.error(error);
            const passwordError = ChangePasswordErrorMap.get(error.code);
            throw passwordError ?? ChangePasswordError.Unknown;
        });
    }

    // upon receiving request with valid username, emails the user a verification code to use to reset password
    static forgotPassword(username: string) {
        return Auth.forgotPassword(username).catch((error) => {
            Logger.error(error);
            const passwordError = ResetPasswordErrorMap.get(error.code);
            throw passwordError ?? ResetPasswordError.Unknown;
        });
    }

    // upon receiving valid username/verification code pair and new password, resets user password to new password
    static forgotPasswordSubmit(username: string, code: string, newPassword: string) {
        return Auth.forgotPasswordSubmit(username, code, newPassword).catch((error) => {
            Logger.error(error);
            const passwordError = ChangePasswordErrorMap.get(error.code);
            throw passwordError ?? ChangePasswordError.Unknown;
        });
    }
}
